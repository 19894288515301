import React, { Component } from 'react';
import img from '../img/final.svg';
import megaphone from '../img/megaphone.png';
import api from '../requests/api';
import { AppConsumer } from '../components/AppContext';
import { isEmpty } from '../helpers.js';
import Loader from "../components/Loader";
import ModalDialog from "../components/ModalDialog";
import TopBar from "../components/TopBar";
import * as LocalNotifications from "../utils/LocalNotifications";
import Branches from "./Branches";
import withTracker from "../utils/withTracker";
import TrackedButton from "../components/TrackedButton";
import * as Analytics from '../utils/Analytics'


class TurnView extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.cancelTurn = this.cancelTurn.bind(this);
        this.reconectVideocall = this.reconectVideocall.bind(this);
        this.toggleConfirmActionDialog = this.toggleConfirmActionDialog.bind(this);
        this.getCustomRedirect = this.getCustomRedirect.bind(this);
        this.state = {
            loading: false,
            showModal: false,
            isVideocall: false,
            turnNotifiedOfQueueReset: '',
            version: null,
            waitingTimeV2: null,
            waitingTimeTotal: null,
            finishRedirect: null,
            alertTimeNotified: false
        };
        api.companies().getEstimatorVersion().then((res) => {
            this.state.version = res.data;
            this.pollForTurn();
        })
    }

    /**
     * Go to the search view
     */
    goBack() {
        this.context.actions.goToSearchCodeView();
    }

    cancelTurn() {
        const { actions } = this.context;
        this.setState({ loading: true });
        actions.checkInConfirmed(false);
        actions.setTurnStatus("FINALIZED");
        Analytics.event("turn_cancelled_view");
        localStorage.removeItem('waitingTimeTotal');
        actions.cancelTurn(() => {
            this.clearTimePoll();
        })
            .then(() => {
                this.setState({ loading: false });
            })
    }

    reconectVideocall = () => {
        this.props.iframe.src = this.props.iframe.src;
        this.props.onVideocallStarted();
    };

    clearTimePoll() {
        clearTimeout(this.turnPollInterval);
    }

    toggleConfirmActionDialog(showDialog) {
        const { t } = this.context;
        const modalDialog = <ModalDialog
            title={t("TurnView.CANCEL_QUESTION")}
            description={t("TurnView.CANCEL_WARNING")}
            onConfirm={() => { this.toggleConfirmActionDialog(false); this.cancelTurn() }}
            onCancel={() => { this.toggleConfirmActionDialog(false); this.sendEvent(); }}
            confirmId="turn_cancelled"
            cancelId="turn_not_cancelled"
            type={"warn"}
            primaryLabel={t("TurnView.CANCEL")}
            secondaryLabel={t("TurnView.DONT_CANCEL")}
        />
        this.setState({ modal: modalDialog, showModal: showDialog });
    }

    componentDidMount() {
        this.pollForTurn();
        if (this.context.state.actualTurn.finishTime && this.context.state.actualTurn.finishTime !== "") {
            clearTimeout(this.turnPollInterval);
        }
        const path = window.location.pathname.split('/');
        // This solution is for the videocall props are lost
        if (path[1] === "videocall") this.setState({ isVideocall: true });

        api.companies().getFinishRedirect(this.context.state.selectedCompany).then((res) => {
            if(res.data.hasRedirect)
                this.setState({ finishRedirect: res.data.url });
        })
    }

    webPushSubscriptionHasToBeSent = true;

    sendEvent = () => {
        Analytics.event("/turno");
    };

    sum = () => {
        var wtt = this.state.waitingTimeTotal - 1;
        localStorage.setItem('waitingTimeTotal', wtt);
        this.setState({ waitingTimeTotal : wtt});
        if (this.state.waitingTimeTotal <= 0){
            clearInterval(this.interval)
        }
    };

    pollForTurn = () => {
        const { t } = this.context;
        api.turn()
            .searchturn(this.context.state.turnCode)
            .then((res) => {
                const { videoCallUrl, videoCallService } = this.context.state.actualTurn;
                Object.assign(res.data, { videoCallUrl, videoCallService });
                if (this.state.version === 'v2') {
                    if (this.state.waitingTimeV2 == null) {
                        var localWaitingTimeTotal = localStorage.getItem('waitingTimeTotal')
                        if (res.data.jsonDetails.estimatorResult != null) {
                            this.state.waitingTimeV2 = localWaitingTimeTotal && res.data.jsonDetails.estimatorResult.waitingTimeV2 !== '-' ? localWaitingTimeTotal : res.data.jsonDetails.estimatorResult.waitingTimeV2;
                            if (this.state.waitingTimeV2 !== '-') {
                                const wtt = parseInt(this.state.waitingTimeV2);
                                localStorage.setItem('waitingTimeTotal', wtt);
                                this.setState({waitingTimeTotal : wtt });
                                clearInterval(this.interval)
                                this.interval = setInterval(this.sum, 60000);
                            }else{
                                localStorage.removeItem('waitingTimeTotal');
                            }
                        }else{
                            this.state.waitingTimeV2 = '-';
                            localStorage.removeItem('waitingTimeTotal');
                        }
                    } else {
                        if (res.data.jsonDetails.estimatorResult != null && (res.data.jsonDetails.estimatorResult.waitingTimeV2 === '-' && this.state.waitingTimeV2 !== '-')) {
                            this.state.waitingTimeV2 = res.data.jsonDetails.estimatorResult.waitingTimeV2;
                            localStorage.removeItem('waitingTimeTotal');
                        } else if (res.data.jsonDetails.estimatorResult != null && (res.data.jsonDetails.estimatorResult.waitingTimeV2 !== '-' && this.state.waitingTimeV2 === '-')) {
                            this.state.waitingTimeV2 = res.data.jsonDetails.estimatorResult.waitingTimeV2;
                            const wtt = parseInt(this.state.waitingTimeV2);
                            this.setState({ waitingTimeTotal :  wtt });
                            localStorage.setItem('waitingTimeTotal', wtt);
                            clearInterval(this.interval)
                            this.interval = setInterval(this.sum, 60000);
                        }
                    }
                }
                this.context.actions.setActualTurn(res.data);

                if (res.data.status === "ANNOUNCED" && res.data.jsonDetails.queue.attentionTimeNotification!==null) {
                    let alertTime = res.data.jsonDetails.queue.attentionTimeNotification;
                    let callTime = new Date(res.data.jsonDetails.lastUpdate);
                    let now = new Date();
                    let toleranceTime = new Date(callTime.setMinutes(callTime.getMinutes() + alertTime));
                    if(toleranceTime < now && !this.state.alertTimeNotified){
                        if(this.props.onAttentionTimeAlert){
                            this.props.onAttentionTimeAlert();
                        }
                        else{
                            let modalDialog = <ModalDialog
                                description={res.data.jsonDetails.queue.attentionTimeNotificationText}
                                type={"accept"}
                                primaryLabel={t("TurnView.ACCEPT")}
                                onConfirm={() => { this.setState({ modal: modalDialog, showModal: false, alertTimeNotified: true }) }}
                                onCancel={() => { this.setState({ modal: modalDialog, showModal: false, alertTimeNotified: true }) }}
                            />
                            this.setState({ modal: modalDialog, showModal: true });
                        }
                        this.setState({ alertTimeNotified: true });
                    }
                }

                if (!res.data.publicVAPIDKey) {
                    LocalNotifications.askForLocalNotificationPermission();
                    this.webPushSubscriptionHasToBeSent = false;
                    clearTimeout(this.turnPollInterval);
                    this.turnPollInterval = setTimeout(this.pollForTurn, 10000);
                    return;
                }

                this.getWebPushSubscriptionAndSendToServer(res)
                    .then(() => {
                        clearTimeout(this.turnPollInterval);
                        this.turnPollInterval = setTimeout(this.pollForTurn, 10000);
                    })
                    .catch(() => {
                        clearTimeout(this.turnPollInterval);
                        this.turnPollInterval = setTimeout(this.pollForTurn, 1000);
                    });
            })
            .catch(reason => {
                console.error("Couldn't fetch updated turn", reason);
                let time = 10000;
                if (this.webPushSubscriptionHasToBeSent) time = 1000;
                if (this.context.state.turnCode) {
                    clearTimeout(this.turnPollInterval);
                    this.turnPollInterval = setTimeout(this.pollForTurn, time);
                };
            });
    };

    // Promise is only rejected if subscription couldn't be sent
    getWebPushSubscriptionAndSendToServer(res) {
        return new Promise(((resolve, reject) => {
            if (res.data.publicVAPIDKey && this.webPushSubscriptionHasToBeSent) {
                LocalNotifications.subscribeToWebPush(res.data.publicVAPIDKey)
                    .then((sub) => {
                        console.debug("Got webpush subscription");

                        api.push().sendSubscriptionForTurn(sub, res.data.code)
                            .then(() => {
                                this.webPushSubscriptionHasToBeSent = false;
                                resolve();
                            })
                            .catch(reason => {
                                console.error("Push subscription couldn't be sent", reason);
                                reject();
                            });
                    })
                    .catch((reason) => {
                        console.error(reason);
                        this.webPushSubscriptionHasToBeSent = false;
                        resolve();
                    });
            } else {
                resolve();
            }
        }));
    }

    componentWillUnmount() {
        clearTimeout(this.turnPollInterval);
        this.webPushSubscriptionHasToBeSent = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { actions, t } = this.context;
        const { turn } = this.props;
        const { turn: prevTurn } = prevProps;
        if (turn && (turn.hasOwnProperty('finishTime') && turn.finishTime !== "")) {
            clearTimeout(this.turnPollInterval);
            actions.resetActualTurn();
        } else if (turn && turn.status && turn.status !== prevTurn.status) {
            const { status: turnStatus } = turn;
            const { status: prevTurnStatus } = prevTurn;

            let modalDialog;

            if (this.context.state.actualTurn.videoCallService == "Teams" && prevTurnStatus === "WAITING_TO_BE_CALLED" && (turnStatus === "ANNOUNCED")) {
                window.open(this.context.state.actualTurn.videoCallUrl, "_blank");
            }
            if (turnStatus === "WAITING_TO_BE_CALLED" && (prevTurnStatus === "CALLED" || prevTurnStatus === "ANNOUNCED")) {
                if(this.context.state.actualTurnStatus !== "VIDEOCALL_END") {
                    this.context.actions.setTurnStatus("EMPTY");
                }
                modalDialog = <ModalDialog
                    title={t("TurnView.ATTENTION_FINISHED")}
                    description={t("TurnView.REQUEUED")}
                    type={"accept"}
                    primaryLabel={t("TurnView.ACCEPT")}
                    onConfirm={() => { this.setState({ modal: modalDialog, showModal: false }) }}
                    onCancel={() => { this.setState({ modal: modalDialog, showModal: false }) }}
                />
                this.state.waitingTimeV2 = null;
                this.state.waitingTimeTotal = null;
            } else if (turnStatus === "ABSENT" && prevTurnStatus !== turnStatus) {
                modalDialog = <ModalDialog
                    title={t("TurnView.ABSENT")}
                    description={t("TurnView.REMOVED_FROM_QUEUE")}
                    type={"accept"}
                    primaryLabel={t("TurnView.ACCEPT")}
                />
            }
            this.setState({ modal: modalDialog, showModal: true, alertTimeNotified: false });
        }
        if (turn.status === "FINALIZED") {
            if(this.props &&  this.props.onVideoCallEnded){
                this.props.onVideoCallEnded();
            }
            actions.setTurnStatus("FINALIZED");
            clearTimeout(this.turnPollInterval);
        }
    }

    watingForVideocall() {
        const { t } = this.context;

        return <div className='loaderContentDiv'> <div>
            <div class="loaderVideocall"></div>
            <div class="slider">
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
                <div class="slide"></div>
            </div>
        </div>
            <div>
                <div class="slider sliderText">
                    <div class="slide text">{t("TurnView.VIDEOCALL_START_SOON")}</div>
                    <div class="slide text">{t("TurnView.CAMERA_MICRO_PERMIT")}</div>
                    <div class="slide text">{t("TurnView.STABLE_CONECTION")}</div>
                    <div class="slide text">{t("TurnView.CHECK_PERMISSIONS")}</div>
                </div>
            </div> </div>
    }

    getCustomRedirect() {
        window.location.replace(this.state.finishRedirect);
    }

    render() {
        let isWaitingForVideocall = this.props.isWaitingForVideocall;
        const { turn: currentTurn } = this.props;
        return (
            <AppConsumer>
                {({ state, t }) =>
                    isEmpty(currentTurn) ? (
                        <div className="turnView">
                            <div className="content finished">
                                <TopBar
                                    title={this.context.state.selectedCompany}
                                />
                                <div className="viewSelectQueue__imgContainer">
                                    <div className="card card-info">
                                        <img src={img} alt="" className="img-responsive viewSelectQueue__img" />
                                        <h2 className="deb-main-title" id="turnFinishTitle"> {t("TurnView.TURN_FINISHED")} </h2>
                                        <p className="deb-text deb-text--center" id="turnFinishText">
                                            {t("TurnView.GO_BACK_HOME")}
                                        </p>
                                        <TrackedButton className="button button-calm button-block button-primary"
                                            id="goBackBtn"
                                            onClick={this.state.finishRedirect === null ? this.goBack : this.getCustomRedirect}>
                                         {t("TurnView.BACK")}
                                        </TrackedButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="turnView content">
                            <Loader loading={this.state.loading} fullscreen={false}>
                                {this.state.showModal && this.state.modal}
                                <div className="container-card-turn">
                                    <div className="not-finished has-header card card-info card-info--turn2">
                                        <div className="turn-time">
                                            <div className="row turnView__turn-container content">
                                                <div className="card card-info card-info--turn">
                                                    <div className="item item-text-wrap">
                                                        <div className="turnView__turn-box ">
                                                            <h2 className="deb-main-title" id="turnTitle"> {t("TurnView.YOUR_TURN")} </h2>
                                                            <p className="turnView__turn" id="turnJsonDetailsTurn">
                                                                {currentTurn.jsonDetails.turn}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {
                                                        /*
                                                            <h1 className="deb-main-title turnView__title">Información de turno</h1>
                                                            TODO: optimizar con funciones que devuelvan HTML de forma más prolija o un componente que resuelva esa lógica
                                                            1- Si existe estimatorResult.waitingTime y no es 0 lo muestro
                                                            2- Si existe estimatorResult.waitingTime y es 0 muestro no hay tiempo de espera
                                                            3- Si no existe estimatorResult.waitingTime muestro averageWaitingTime
                                                            4- Si los dos llegan vacios o en 0 muestro que no hay tiempo de espera - En breve será llamado
                                                        */
                                                    }
                                                    { this.state.version === 'v1' ? (currentTurn.jsonDetails.hasOwnProperty('estimatorResult') && currentTurn.jsonDetails.estimatorResult !== null ? (
                                                        <div className="item item-text-wrap no-border-bottom">
                                                            {currentTurn.jsonDetails.estimatorResult.waitingTime === 0 ? (
                                                                // 1
                                                                <div className="turnView__turn-box" id="turnNextTurn">
                                                                    {
                                                                        (isWaitingForVideocall || this.state.isVideocall) &&
                                                                        <>
                                                                        {this.watingForVideocall()}
                                                                        </>
                                                                    }
                                                                    <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.YOUR_TURN_IS_NEXT")}
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                // 2
                                                                <div className="turnView__turn-box" id="turnVideoCallTitle">
                                                                    {
                                                                        (isWaitingForVideocall || this.state.isVideocall) &&
                                                                        <>
                                                                        {this.watingForVideocall()}
                                                                        </>
                                                                    }
                                                                    <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.ESTIMATED_TIME")}
                                                                    </span>
                                                                    <p className="turnView__turn" id="turnEstimatorResult">
                                                                        {parseInt(currentTurn.jsonDetails.estimatorResult.waitingTime)}{' '}
                                                                        <span>{t("TurnView.MIN")}</span>{' '}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="item item-text-wrap no-border-bottom">
                                                            {currentTurn.jsonDetails.averageWaitingTime !== 0 && currentTurn.status === 'WAITING_TO_BE_CALLED' ? (
                                                                // 3
                                                                <div className="turnView__turn-box" id="turnVideoCallTitle2">
                                                                    {
                                                                        (isWaitingForVideocall || this.state.isVideocall) &&
                                                                        <>
                                                                            {this.watingForVideocall()}
                                                                        </>
                                                                    }
                                                                    <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.ESTIMATED_TIME")}
                                                                    </span>
                                                                    <p className="turnView__turn" id="turnMinutesLeft">
                                                                        {currentTurn.jsonDetails.averageWaitingTime !== undefined ? (
                                                                            parseInt(currentTurn.jsonDetails.averageWaitingTime)
                                                                        ) : (
                                                                            "--"
                                                                        )}{' '}
                                                                        <span>{t("TurnView.MIN")}</span>{' '}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div className="item item-text-wrap no-border-bottom">
                                                                    {currentTurn.status === 'ANNOUNCED' ? (
                                                                        <div className="turnView__turn-box" id="turnVideoCallTitle3">
                                                                            {
                                                                                (isWaitingForVideocall || this.state.isVideocall) && this.context.state.actualTurnStatus !== "VIDEOCALL_END" &&
                                                                                <>
                                                                                    {this.watingForVideocall()}
                                                                                </>
                                                                            }
                                                                            {
                                                                                this.context.state.actualTurnStatus === "VIDEOCALL_END" &&
                                                                                <>
                                                                                <span className="deb-text turnView__waiting-videocall-title">
                                                                                    {t("TurnView.TURN_VIDEOCALLFINISH")}
                                                                                </span>
                                                                                </>
                                                                                

                                                                            }
                                                                            {
                                                                                this.context.state.actualTurn.videoCallService && this.context.state.actualTurn.videoCallService === "Teams" &&
                                                                                <>
                                                                                <button class="button button-calm button-block button-primary" onClick={()=> window.open(this.context.state.actualTurn.videoCallUrl, "_blank")}> Abrir videollamada </button>
                                                                                </>
                                                                            }
                                                                            <h2 className="deb-main-title deb-main-title--turnAttended">
                                                                                {t("TurnView.TURN_ATTENDED")}

                                                                            </h2>
                                                                            <img src={megaphone} id="megaphone" alt="" className="img-fixed-responsive" />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="turnView__turn-box" id="turnVideoCallTitle3">
                                                                            {
                                                                                isWaitingForVideocall ?
                                                                                <>
                                                                                    {this.watingForVideocall()}
                                                                                </>
                                                                                    :
                                                                                    <span className="deb-text turnView__waiting-title">
                                                                                        {t("TurnView.WILL_BE_CALLED")}
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )) : (currentTurn.status === 'WAITING_TO_BE_CALLED' ? (
                                                        <div className="item item-text-wrap no-border-bottom">
                                                            { this.state.waitingTimeV2 == null ? ('') : (this.state.waitingTimeV2 === '-' ? (
                                                                    // 1
                                                                    <div className="turnView__turn-box" id="turnNextTurn">
                                                                        <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.ESTIMATED_TIME_NOT_DATA")}
                                                                        </span>
                                                                        { (isWaitingForVideocall || this.state.isVideocall) &&
                                                                            <>
                                                                                {this.watingForVideocall()}
                                                                            </>
                                                                        }
                                                                    </div>
                                                            ) : this.state.waitingTimeTotal <= 0 ? (
                                                                // 2
                                                                <div className="turnView__turn-box" id="turnNextTurn">
                                                                    <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.ESTIMATED_TIME_ZERO")}
                                                                    </span>
                                                                    { (isWaitingForVideocall || this.state.isVideocall) &&
                                                                            <>
                                                                                {this.watingForVideocall()}
                                                                            </>
                                                                        }
                                                                </div>
                                                        ) : (
                                                            // 3
                                                            <div className="turnView__turn-box"  id="turnVideoCallTitle">
                                                                {
                                                                    (isWaitingForVideocall || this.state.isVideocall) &&
                                                                    <>
                                                                        {this.watingForVideocall()}
                                                                    </>
                                                                }
                                                                { currentTurn.jsonDetails && currentTurn.jsonDetails.estimatorResult && currentTurn.jsonDetails.estimatorResult.turnsInFrontV2 == '0' ?     
                                                                (
                                                                    <div className="turnView__turn-box" id="turnsInFrontV2Zero">
                                                                    <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.TURN_IN_FRONT_ZERO")}
                                                                    </span>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <span className="deb-text turnView__waiting-title">
                                                                        {t("TurnView.ESTIMATED_TIME")}
                                                                        </span>
                                                                        <p className="turnView__turn" id="turnEstimatorResult">
                                                                            {this.state.waitingTimeTotal}{' '}
                                                                            <span>{t("TurnView.MIN")}</span>{' '}
                                                                        </p>
                                                                    </div>
                                                                )
                                                                }

                                                            </div>
                                                        ))}
                                                        </div>
                                                    ) : (
                                                        <div className="item item-text-wrap no-border-bottom">
                                                            {currentTurn.status === 'ANNOUNCED' ? (
                                                                <div className="turnView__turn-box" id="turnVideoCallTitle3">
                                                                    {
                                                                        (isWaitingForVideocall || this.state.isVideocall) && this.context.state.actualTurnStatus !== "VIDEOCALL_END" &&
                                                                        <>
                                                                            {this.watingForVideocall()}
                                                                        </>
                                                                    }
                                                                     {
                                                                                this.context.state.actualTurnStatus === "VIDEOCALL_END" &&
                                                                                <>
                                                                                <span className="deb-text turnView__waiting-videocall-title">
                                                                                    {t("TurnView.TURN_VIDEOCALLFINISH")}
                                                                                </span>
                                                                                </>


                                                                            }
                                                                            {
                                                                                this.context.state.actualTurn.videoCallService && this.context.state.actualTurn.videoCallService === "Teams" &&
                                                                                <>
                                                                                <button class="button button-calm button-block button-primary" onClick={()=> window.open(this.context.state.actualTurn.videoCallUrl, "_blank")}> Abrir videollamada </button>
                                                                                </>
                                                                            }
                                                                    <h2 className="deb-main-title deb-main-title--turnAttended">
                                                                        {t("TurnView.TURN_ATTENDED")}

                                                                    </h2>
                                                                    <img src={megaphone} id="megaphone" alt="" className="img-fixed-responsive" />
                                                                </div>
                                                            ) : (
                                                                <div className="turnView__turn-box" id="turnVideoCallTitle3">
                                                                    {
                                                                        isWaitingForVideocall ?
                                                                        <>
                                                                            {this.watingForVideocall()}
                                                                        </>
                                                                            :
                                                                            <span className="deb-text turnView__waiting-title">
                                                                                {t("TurnView.WILL_BE_CALLED")}
                                                                            </span>
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                    {
                                                        currentTurn.jsonDetails.hasOwnProperty('hasPriority') && currentTurn.jsonDetails.hasPriority
                                                        &&
                                                            <div className='priority-msg'>
                                                                <i class="fa fa-arrow-circle-up priority-msg_icon" aria-hidden="true"></i> <span className="deb-text priority-msg_main" id="turnTitle">{t("TurnView.PRIORITY_TURN")} </span><br/>
                                                                <span className="deb-text" id="turnSubTitle"> {t("TurnView.PRIORITY_TURN_SUBTITLE")} </span>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            <ul className="list turn-info-box">
                                                <li className="item turnView__info" id="turnCodeBlock">
                                                    {t("TurnView.TURN_CODE")}
                                                    <span className="data" onClick={this.copyCodeToClipboard}>
                                                        {currentTurn.code}
                                                        <i className={"fa fa-copy data__action"} />
                                                    </span>
                                                </li>
                                                <li className="item turnView__info" id="nextTurnBlock">
                                                    {t("TurnView.NEXT_TO_BE_CALLED")}
                                                    <span className="data">
                                                        <strong>{currentTurn.jsonDetails.actualTurn}</strong>
                                                    </span>
                                                </li>
                                                { this.state.version === 'v1' && currentTurn.jsonDetails.estimatorResult  ? (
                                                    <li className="item turnView__info" id="watingTurnsBlock">
                                                        {t("TurnView.WAITING_TURNS")}
                                                        <span className="data">
                                                            <strong>
                                                                {currentTurn.jsonDetails.estimatorResult.turnsInFront}
                                                            </strong>
                                                        </span>
                                                    </li>
                                                ) : ((this.state.version === 'v2' && currentTurn.jsonDetails.estimatorResult ) ? (
                                                    <li className="item turnView__info" id="watingTurnsBlock">
                                                        { t("TurnView.WAITING_TURNS") }
                                                        <span className="data">
                                                            <strong>
                                                                {currentTurn.jsonDetails.estimatorResult.turnsInFrontV2}
                                                            </strong>
                                                        </span>
                                                    </li>
                                                ) : (''))}
                                                <li className="item turnView__info" id="turnCodeBlock">
                                                    {t("TurnView.WORKER")}
                                                    <span className="data">
                                                        {currentTurn.workerName || '-'}
                                                    </span>
                                                </li>
                                                <li className="item turnView__info" id="companyBlock">
                                                    {t("TurnView.COMPANY")} <span className="data"> {state.selectedCompany} </span>
                                                </li>
                                                {state.selectedBranch ? (
                                                    <li className="item turnView__info" id="branchBlock">
                                                        {t("TurnView.BRANCH")} <span className="data"> {Branches.getBranchTitle(state.selectedBranch)} </span>
                                                    </li>
                                                ) : ('')}
                                                {state.selectedQueue ? (
                                                    <li className="item turnView__info" id="queueBlock">
                                                        {t("TurnView.QUEUE")} <span className="data"> {state.selectedQueue.name} </span>
                                                    </li>
                                                ) : ('')}
                                            </ul>
                                        </div>
                                        <div className="container-button">
                                                {
                                                    this.context.state.actualTurnStatus === "VIDEOCALL_END" &&
                                                    <>
                                                        <TrackedButton
                                                            id="recall_turn"
                                                            className="button button-calm button-block button-primary"
                                                            onClick={this.reconectVideocall}>
                                                            {t("TurnView.TURN_RECALL")}
                                                        </TrackedButton>
                                                    </>
                                                }
                                            {
                                                currentTurn.status !== 'ANNOUNCED' &&
                                                <>
                                                    <TrackedButton
                                                    id="maybe_cancel"
                                                    className="button button-calm button-block button-primary deb-button-cancel deb-button-cancel_fixhover"
                                                    onClick={() => this.toggleConfirmActionDialog(true)}>
                                                    {t("TurnView.CANCEL_TURN")}
                                                </TrackedButton>
                                                </>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </Loader>
                        </div>
                    )
                }
            </AppConsumer>
        );
    }

    copyCodeToClipboard = (ev) => {
        const dataElements = ev.target;
        window.temp = Object.assign({}, ev);
        if (dataElements) {
            document.getSelection().removeAllRanges();
            const range = document.createRange();
            range.selectNode(dataElements.previousSibling);
            document.getSelection().addRange(range);

            document.execCommand("copy");
            document.getSelection().removeAllRanges();
        }

    }
}

TurnView.contextType = AppConsumer;
export default withTracker(TurnView);
