import axios from 'axios';
import GlobalConfig from "../global-conf";
const localUrl = '/mockData';
const serverUrl = "";
let api;

let localApi = {
    test() {
        return {
            test: () => axios.get(`${localUrl}/test.json`),
            finishedturn: () => axios.get(`${localUrl}/finishedTurn.json`),
            createCustomer: () => axios.get(`${localUrl}/finishedTurn.json`),
        }
    },
    auth(){
        return {
            login: (user) => axios.post(`${serverUrl}/debdash/api/authenticate`, user),
            isAuth: () => axios.get(`${serverUrl}/debdash/api/isauthenticated`)
        }
    },
    companies(){
        return{
            getAll: () => axios.get(`${localUrl}/companies.json`),
            getFinishRedirect: (name) => new Promise(res => res({
                hasRedirect: false,
                url: ""
            }))
        }
    },
    companyByName(){
        return{
            getAll: (name) => {
                return new Promise((resolve, reject)=>{
                    axios.get(`${localUrl}/companies.json`).then(res=>{
                        const comp = res.data.find(c => c.name === name);
                        if(comp) resolve({data: comp});
                        else reject();
                    }).catch(reject);
                })
            }
        }
    },
    customers(){
        return{
            getReducedInfo: (body, companyName) => new Promise(res => res({data: []})),
            getPublicKey: () => new Promise((res) => res(`-----BEGIN RSA PUBLIC KEY-----
MIIBCgKCAQEAjUf1IOBajRosdAXbrEDGQzmr/qK1S3aPQAhmJs2s6tO330v8KftN
CH8zAd2HiK7s5qmAClBxPTqu4RzUA148zzH+hkkvtz/w9o8lAen87lRJbG/DHJzs
KP/8EGHXSdx3BoNAqULiFIxsae0mcDrJxD1efzfOVhMA6edbNfhBpE1SbqFCIPwn
PxU17Six4NqQC+gHsowMkosOdm/5a8Y4glSHN3bXJMzPzFGKytTxckb3mSAjBygY
uBpr/YviKhENcMelJGaRbuBp3BxEeyQkpbAZu028KtmHxLIDtElgEgBVOm1nCU8k
6oPF/U26UM6PELPK0fWgJz9IuD/oiP9FewIDAQAB
-----END RSA PUBLIC KEY----- `))
        }
    },
    customerFieldsInfo(){
        return{
            getAll: (companyName) => axios.get(`${localUrl}/customerfields.json?`)
        }
    },
    queues(){
        return{
            getAll: (name) => axios.get(`${localUrl}/queues.json?`)
        }
    },
    appoitmentsQueues(){
        return{
            getAll: (companyName, scheduleId, mBranchId) => axios.get(`${localUrl}/appoqueues.json?`)
        }
    },
    branches(){
        return{
            getAll: (name) => axios.get(`${localUrl}/branches.json?${name}`),
            computeDistances: (lat, long, branches) => axios.get(`${localUrl}/distances.json`)
        }
    },
    turn(){
        return{
            new: (queue, branch) => axios.get(`${localUrl}/turn.json?queue=${queue}&branch=${branch}`),
            searchturn: (code) => axios.get(`${localUrl}/turn.json?${code}`),
            searchTurnOrAppointment: function(code, body){
                return axios.get(`${localUrl}/appo.json?${code}`).then(res => {
                    res.data.startAt = new Date()
                    return res;
                });
            },
            cancel: (code) => axios.get(`${localUrl}/turn.json?code=${code}&action=cancel`)
        }
    },
    push(){
        return {
            sendSubscriptionForTurn: (sub, code) => axios.post(`/push/subscription`, sub)
        }
    },
    scripts() {
        const mapsUrl = `${GlobalConfig.gmapsUrl}?key=${GlobalConfig.gmapsKey}`;
        const firstScriptElement = document.getElementsByTagName('script')[0];

        const onScriptLoaded = function( scriptElement, callback, _, isAbort ) {
            if(isAbort || !scriptElement.readyState || /loaded|complete/.test(scriptElement.readyState) ) {
                scriptElement.onload = scriptElement.onreadystatechange = null;

                if(!isAbort && callback) setTimeout(callback, 0);
            }
        };
        return {
            loadMapApi: () => {
                return new Promise((resolve, reject)=>{
                    const scriptElement = document.createElement('script');
                    scriptElement.async = true;
                    scriptElement.onreadystatechange = onScriptLoaded.bind(this, scriptElement, resolve);
                    scriptElement.onload = scriptElement.onreadystatechange;
                    scriptElement.src = mapsUrl;
                    firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
                });
            }
        }
    },
    getTermAndConditionById(){
        return{
            getAll: (id,name) => axios.get(`${localUrl}/termAndCondition.json?id=${id}&name=${name}`)
        }
    }
};

let productionApi = {
    auth(){
        return {
            login: (user) => axios.post(`${serverUrl}/debdash/api/authenticate`, user),
            isAuth: () => axios.get(`${serverUrl}/debdash/api/isauthenticated`)
        }
    },
    companies(){
        return{
            getAll: () => axios.get(`${serverUrl}/api/v1/companies`),
            getEstimatorVersion: () => axios.get(`${serverUrl}/api/v1/company/estimatorVersion`),
            getFinishRedirect: (name) => axios.get(`${serverUrl}/api/v1/company/${name}/finishRedirect`)
        }
    },
    companyByName(){
        return{
            getAll: (name) => axios.get(`${serverUrl}/api/v1/company/${name}`)
        }
    },
    customers(){
        return{
            getReducedInfo: (body, companyName) => axios.post(`${serverUrl}/api/v2/customer/getReducedInfoCustomers`,body, {
                params: {
                    companyName: companyName,
                }
            }),
            getPublicKey: () => axios.get(`${serverUrl}/api/v2/customerencryption/pubkey`)
        }
    },
    customerFieldsInfo(){
        return{
            getAll: (companyName) => axios.get(`${serverUrl}/api/v1/customerfield/info/${companyName}`)
        }
    },
    queues(){
        return{
            getAll: (name) => axios.get(`${serverUrl}/api/v1/company/${name}/queue/reduced`),
            branchInfo: (branchId) => axios.get(`${serverUrl}/api/queues/branchInfo/queue/${branchId}`)
        }
    },
    appoitmentsQueues(){
        return{
            getAll: (companyName, scheduleId, mBranchId) => axios.get(`${serverUrl}/api/v1/queueFromSchedule/${companyName}/${scheduleId}`, {
                params: {
                    branchId: mBranchId,
                }
            })
        }
    },
    getCustomersWithUuidAndCaptcha(){
        return{
            getAll: (companyName, token, body) => axios.get(`${serverUrl}/api/v2/customer/getCustomersWithUuidAndCaptcha/${companyName}`,{
                params: {
                    captchaToken: token,
                    ...body
                },
            })
        }
    },
    branches(){
        return{
            getAll: (name) => axios.get(`${serverUrl}/api/v1/company/${name}/branch`),
            computeDistances: (name, lat, long) => axios.get(`${serverUrl}/api/v1/company/${name}/branchesdistance`, {
                params: {
                    latitude: lat,
                    longitude: long
                }
            })
        }
    },
    turn(){
        return{
            new: (queue, branch, body) => axios.post(`${serverUrl}/api/v2/queue/${queue}/branch/${branch}/enqueue`, body),
            searchturn: (code) => axios.get(`${serverUrl}/api/v1/turn/code/${code}`),
            searchTurnOrAppointment: (code, body) => axios.post(`${serverUrl}/api/v1/turnOrAppointment/code/${code}`, body),
            searchTurnOrAppointmentWhitCaptcha: (company, code, token) => axios.get(`${serverUrl}/api/v1/turnOrAppointmentWithCaptcha/code/${company}/${code}`, {
                params: {
                    captchaToken: token,
                }
            }),
            cancel: (code) => axios.post(`${serverUrl}/api/v1/turn/${code}/cancel`)
        }
    },
    push(){
        return {
            sendSubscriptionForTurn: (sub, code) => axios.post(`${serverUrl}/api/v1/turn/${code}/subscription`, sub)
        }
    },
    scripts() {
        const mapsUrl = `${GlobalConfig.gmapsUrl}?key=${GlobalConfig.gmapsKey}`;
        const firstScriptElement = document.getElementsByTagName('script')[0];

        const onScriptLoaded = function( scriptElement, callback, _, isAbort ) {
            if(isAbort || !scriptElement.readyState || /loaded|complete/.test(scriptElement.readyState) ) {
                scriptElement.onload = scriptElement.onreadystatechange = null;

                if(!isAbort && callback) setTimeout(callback, 0);
            }
        };
        return {
            loadMapApi: () => {
                return new Promise((resolve, reject)=>{
                    const scriptElement = document.createElement('script');
                    scriptElement.async = true;
                    scriptElement.onreadystatechange = onScriptLoaded.bind(this, scriptElement, resolve);
                    scriptElement.onload = scriptElement.onreadystatechange;
                    scriptElement.src = mapsUrl;
                    firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
                });
            }
        }
    },
    getTermAndConditionById(){
        return{
            getAll: (id,name) => axios.get(`${serverUrl}/api/v1/termandcondition/${id}/company/${name}`)
        }
    }
};

process.env.NODE_ENV === 'development' ? api = localApi : api = productionApi;

export default api;
