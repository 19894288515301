import React, { Component } from 'react';
import img from '../img/sucursales_img.svg';
import { isEmpty } from '../helpers.js';
import { AppConsumer } from '../components/AppContext';
import Branches from "./Branches";
import TrackedButton from "../components/TrackedButton";

class BranchesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titleFilter: '',
            onlyScheduleForToday: true,
        };
    }

    componentDidMount() {
        const {selectedBranch} = this.props;
        if (!isEmpty(selectedBranch)) {
            const branchTitles = document.getElementsByClassName(`branch-title-${selectedBranch.id}`);
            if (branchTitles[0]) {
                branchTitles[0].scrollIntoView();
                // Prithee f'rgive mine own sins, oh front endeth gods
                window.scrollBy(0, -70);
            }
        }
    }

    showAllOpenDetails = () => {
        this.setState({onlyScheduleForToday: false});
    }

    selectBranch = (branch) => {
        this.setState({onlyScheduleForToday: true}, this.props.selectBranchHandler.bind(this, branch));
    }

    render() {
        const { branches:sortedBranches, selectedBranch, branchSorting } = this.props;
        const {onlyScheduleForToday: justToday} = this.state;
        return (
        <AppConsumer>
            {({t}) => (
                <div className="card card-info">
                    <form
                        onSubmit={this.props.branchSelectedEventHandler}
                        className="content">
                        <div className="viewSelectQueue__imgContainer">
                            <img
                                src={img}
                                alt={t("BranchesList.IMAGE_BRANCHES")}
                                className="img-responsive viewSelectQueue__img viewSelectQueue__maxImg"
                            />
                            <h2 className="deb-main-title viewSelectQueue__title">{t("BranchesList.SELECT_BRANCH")}</h2>
                        </div>
                        <div className="viewSelectBranch__list">
                            <button type={`button`} className={`button button-calm viewSelectBranch__sortByDistanceButton`}
                                    onClick={this.props.toggleBranchSortHandler}>
                                {branchSorting === Branches.BranchSorting.DISTANCE ?
                                    t("BranchesList.SORT_NAME") :
                                    t("BranchesList.SORT_DISTANCE")}
                            </button>
                            <div className="custom-radio-selector">
                                <span className={"viewSelectQueue__search-bar"}>
                                    <i className={`fa fa-search`} />
                                    <input type="text" name={"filter"} onChange={this.handleFilterChange} placeholder={t("BranchesList.FIND_BRANCH")}/>
                                </span>
                                { (sortedBranches.length > 0 && (sortedBranches.map((branch, i) => (
                                    <div
                                        key={branch.id}
                                        className={`inputGroup 
                                        ${(this.titleFilterMatches(Branches.getBranchTitle(branch)) || this.state.selectedBranch === branch) ? 
                                            '' : `inputGroup-hidden`}`}>
                                        <input id={branch.id} type="radio" name="turn" value={branch.id}
                                            onChange={this.props.branchSelectedChangeEventHandler}
                                            checked={branch.id === selectedBranch.id}/>
                                        <div className='viewSelectBranch__branch-title'
                                            onClick={this.selectBranch.bind(this, branch)}>
                                            <label
                                                className={`branch-title-${branch.id}`}
                                                htmlFor={branch.id}>
                                                {Branches.getBranchTitle(branch)}
                                            </label>
                                            <span className='viewSelectBranch__branch-distance'>
                                                {branch.distance ? `A ${(branch.distance/1000).toString().replace(/\.(\d{2})\d*/gi,',$1')} km` : ''}
                                            </span>
                                        </div>
                                        <div className={`info`}>
                                            {Branches.renderBranchOrMBranchWaitingTurnsIfDefined(branch, branch.mBranch, t)}

                                            {Branches.renderBranchOrMBranchAverageWaitingTimeIfDefined(branch, branch.mBranch, t)}

                                            {Branches.renderBranchOrMBranchDireccion(branch, t)}

                                            {Branches.renderBranchOpenDetails(branch, justToday, t)}
                                            {(branch.openDetails || (branch.mBranch && branch.mBranch.openDetails)) && justToday &&
                                                <div className={"info-row"}>
                                                    <div className={"info-left"}/>
                                                    <div className={"info-right info-right__button-expand"}>
                                                        <i className={`fa fa-plus-circle`} onClick={this.showAllOpenDetails} />
                                                    </div>
                                                </div>
                                            }

                                            {branch.open ? (
                                                (!branch.monitorInfo || (
                                                    (isNaN(parseFloat(branch.monitorInfo.waitingTurns)) ||
                                                        parseFloat(branch.monitorInfo.waitingTurns) === 0) &&
                                                    isNaN(parseFloat(branch.monitorInfo.avgWaitingTime)) &&
                                                    !branch.openDetails) ) && (
                                                    Branches.renderBranchInfo(t("BranchesList.AVAILABLE_BRANCH"))
                                                )
                                            ) : (
                                                <div className={`viewSelectBranch__closed`}>
                                                    {t("BranchesList.BRANCH_CLOSED")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))))}
                            </div>
                        </div>
                        <div className="row">
                            <TrackedButton  className="button button-calm button-block button-primary button-primary--fixed"
                                disabled={isEmpty(selectedBranch) ||
                                    (!Branches.isOpen(selectedBranch) &&
                                        selectedBranch.mBranch && selectedBranch.mBranch.enqueueAllowedWhileClosed === false) ?
                                    'disabled' : ''}
                                    id="branch_list_get_turn"
                                    >
                                    {t("BranchesList.GET_TURN")}
                            </TrackedButton>
                            <button
                                className="button button-calm button-block button-secondary button-secondary--fixed"
                                onClick={this.props.toggleViewHandler ? this.props.toggleViewHandler : undefined}
                                disabled={(typeof this.props.toggleViewHandler !== "function") && true}>
                                <i className="fa fa-map-marker"></i>
                            </button>
                        </div>
                    </form>
                </div>
            )
        }
    </AppConsumer>   
    )
}

    titleFilterMatches = (name) => {
        const lowerCaseNormalizedName = name.toLocaleLowerCase().normalize('NFKD');
        const lowerCaseNormalizedFilter = this.state.titleFilter.toLocaleLowerCase().normalize('NFKD');

        // remove code points which make composite characters
        return lowerCaseNormalizedName.replace(/[^\w\s.-/\\]/g, '')
            .indexOf(lowerCaseNormalizedFilter.replace(/[^\w\s.-/\\]/g, '')) !== -1;
    };

    handleFilterChange = (ev) => {
        this.setState({titleFilter: ev.target.value});
    };

}

BranchesList.contextType = AppConsumer;
export default BranchesList;