import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'es',
        supportedLngs: ['es', 'pt', 'en', 'fr'],
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/static/lang/{{lng}}.json`
        },
        react: {
            useSuspense: false,
        },
});

export default i18n;