import React, {Component} from 'react';
import {AppConsumer} from "../components/AppContext";
import Loader from '../components/Loader';
import TopBar from "../components/TopBar";
import ModalDialog from "../components/ModalDialog";
import withTracker from "../utils/withTracker";
import {NotificationManager} from "react-notifications";
import * as Analytics from '../utils/Analytics'
import api from '../requests/api.js';
import TrackedButton from "../components/TrackedButton";


class ConfirmView extends Component {
    constructor(props) {
        super(props);
        this.setShowCancelDialog = this.setShowCancelDialog.bind(this);
        this.state = {
            loading: false,
            showProfileForm: false,
            fetchingTurn: false,
            showCancelDialog: false,
            showTyCModal: false,
            termAndCondition: {},
            tycChecked : false
        }
    }

    /**
     * Handle searchCode changes.
     * @param value
     */

    getTurnAndGoToTurnView = () => {
        localStorage.removeItem('waitingTimeTotal');
        if (this.state.fetchingTurn) return;
        this.setState({fetchingTurn: true});
        const {actions} = this.context;
        Analytics.event("confirm");
        actions.getTurn().then((data) => {
            if (!data.videoCallUrl)
                this.props.history.push('/turno');
            else
                this.props.history.push('/videocall');
        }).finally(_ => {
            this.setState({fetchingTurn: false});
            actions.checkInConfirmed(true);
        });
    };

    setShowCancelDialog(showDialog) {
        this.setState({showCancelDialog: showDialog});
    }

    setShowTyCModal(showModal) {
        this.setState({showTyCModal: showModal});
    }

    setTyCChecked(tycAceptation){
        this.setState({tycChecked : tycAceptation});
    }

    cancelTurn() {
        const {actions} = this.context;
        Analytics.event("cancel_confirm");
        actions.checkInConfirmed(false);
        actions.resetActualTurn();
        this.props.history.push('/buscar/' + this.context.state.selectedCompany);
    }

    async getTyCById(){
        if(this.context.state.selectedQueue.termAndCondition){
            const id = this.context.state.selectedQueue.termAndCondition.id;
            const companyName = this.context.state.selectedCompany;
            return api.getTermAndConditionById().getAll(id, companyName);
        }
    }

    async componentDidMount() {
        const {state, t} = this.context;
        const response = await this.getTyCById();
        if(response){
            this.setState({ termAndCondition: response.data });
        }else{
            this.setTyCChecked(true);
        }

        if (state.actualTurn.code && !state.actualTurn.finishTime) {
            NotificationManager.warning(t("ConfirmView.CANCEL_BEFORE"));
            if(state.actualTurn.videoCallUrl) {
                this.props.history.push('/videocall');
            } else {
                this.props.history.push('/turno');
            }
        }
        if (state.actualTurnStatus === "FINALIZED") {
            NotificationManager.warning(t("ConfirmView.FINISH_GET_NEW"));
            this.props.history.push('/turno');
        }
        const {selectedQueue} = this.context.state;
        const {appointmentClientData} = this.context.state;
        if (!selectedQueue.appointmentCode) return;
        const appointmentData = {
            branches: selectedQueue.branches,
            customer: {
                firstName: selectedQueue.firstName,
                lastName: selectedQueue.lastName,
                dni: selectedQueue.dni,
                cuil: selectedQueue.cuil,
                cuit: selectedQueue.cuit,
                externalId: selectedQueue.clientId
            },
            code: selectedQueue.appointmentCode,
            schedule: {
                qScheduleId: selectedQueue['schedule.id']
            },
            extraFields: appointmentClientData[0].extraFields
        };
        const branch = {
            id: selectedQueue.branch['cloudBranch.id'],
            label: selectedQueue.branch.label,
            name: selectedQueue.branch.name
        };
        appointmentData.branch = appointmentData.mobileBranch = branch;
        this.context.actions.setAppointmentTurn(appointmentData);

    }

    render() {
        const {loading} = this.state;
        return (
            <AppConsumer>
                {({t}) =>
                    <Loader loading={loading} fullscreen={false}>
                        {this.state.showCancelDialog &&
                        <ModalDialog
                            title={t("ConfirmView.CANCEL_QUESTION")}
                            description={t("ConfirmView.CANCEL_WARNING")}
                            onConfirm={() => {
                                this.setShowCancelDialog(false);
                                this.cancelTurn()
                            }}
                            onCancel={() => {
                                this.setShowCancelDialog(false)
                            }}
                            type={"warn"}
                            primaryLabel={t("ConfirmView.CANCEL")}
                            secondaryLabel={t("ConfirmView.NO")}
                        />
                        }
                        <div className="viewSearchCode">
                            {this.context.state.isAppointment || this.context.state.selectedQueue.preSelecetd ?
                                <TopBar
                                    title={this.context.state.selectedCompany}
                                />
                                :
                                <TopBar
                                    linkBack={"/tramites"}
                                    title={this.context.state.selectedCompany}
                                />
                            }
                            <div className="content">
                                <div className="container-body">
                                    <div className="card card-info">
                                        <div className="viewSelectQueue__imgContainer">
                                            <h2 className="deb-main-title viewSelectQueue__title">{t("ConfirmView.TITLE")}</h2>
                                        </div>
                                        <div className="col search-container">
                                            <ul className="list turn-info-box">
                                                {
                                                    this.context.state.isAppointment &&
                                                    <li className="item turnView__info" id="confirmViewCode">
                                                        {t("ConfirmView.TURN_CODE")}
                                                        <span className="data" onClick={this.copyCodeToClipboard}>
                                                        {this.context.state.appointmentCode}
                                                            <i className={"fa fa-copy data__action"}/>
                                                    </span>
                                                    </li>
                                                }

                                                <li className="item turnView__info" id="confirmViewCompany">
                                                    {t("ConfirmView.COMPANY")} <span
                                                    className="data"> {this.context.state.selectedCompany} </span>
                                                </li>
                                                {this.context.state.selectedBranch ? (
                                                    <li className="item turnView__info" id="confirmViewBranch">
                                                        {t("ConfirmView.BRANCH")}<span
                                                        className="data"> {this.context.state.selectedBranch.label} {this.context.state.preSelectedBranch.label} {this.context.state.selectedQueue.branchLabel} </span>
                                                    </li>
                                                ) : ('')}
                                                {this.context.state.selectedQueue ? (
                                                    <li className="item turnView__info" id="confirmViewQueue">
                                                        {t("ConfirmView.QUEUE")} <span
                                                        className="data"> {this.context.state.selectedQueue.name} </span>
                                                    </li>
                                                ) : ('')}
                                            </ul>
                                            {this.state.showTyCModal && <ModalDialog
                                              title = {this.state.termAndCondition.label}
                                              description = {this.state.termAndCondition.termAndCondition}
                                              secondaryLabel={t("ConfirmView.CANCEL_BUTTON")}
                                              primaryLabel={t("ConfirmView.ACEPT")}
                                              useMarkdown="si"
                                              onConfirm={() => {
                                                this.setShowTyCModal(false);
                                                this.setTyCChecked(true);
                                              }}
                                              onCancel={() => {
                                                this.setShowTyCModal(false)
                                                this.setTyCChecked(false);
                                              }}
                                             />}
                                            {this.context.state.selectedQueue.termAndCondition && <div>
                                                <input type="checkbox" onClick={() => this.setTyCChecked(!this.state.tycChecked)} checked={this.state.tycChecked} name="" id="termsAndCondition"/>
                                                { this.state.termAndCondition.termAndCondition !== "" ? (<a href="#" className='termsAndConditionAction' onClick={() => this.setShowTyCModal(true)}>{this.state.termAndCondition.label || this.state.termAndCondition.title}</a>)
                                                    : (<span className='termsAndConditionAction'>{this.state.termAndCondition.label || this.state.termAndCondition.title}</span>) }
                                            </div>}
                                            <TrackedButton disabled={!this.state.tycChecked }  className="button button-calm button-block button-primary" onClick={this.getTurnAndGoToTurnView}
                                                id="confirmViewNext"
                                                >
                                                {t("ConfirmView.CONFIRM_BUTTON")}
                                            </TrackedButton>
                                            <TrackedButton className="button button-calm button-block button-primary deb-button-cancel deb-button-cancel__smallMargin" onClick={() => this.setShowCancelDialog(true)}
                                               id="confirmViewCancel"
                                                >
                                                {t("ConfirmView.CANCEL_BUTTON")}
                                            </TrackedButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Loader>
                }
            </AppConsumer>
        )
    }

    copyCodeToClipboard = (ev) => {
        const dataElements = ev.target;
        window.temp = Object.assign({}, ev);
        if (dataElements) {
            document.getSelection().removeAllRanges();
            const range = document.createRange();
            range.selectNode(dataElements.previousSibling);
            document.getSelection().addRange(range);

            document.execCommand("copy");
            document.getSelection().removeAllRanges();
        }

    }
}

ConfirmView.contextType = AppConsumer;
export default withTracker(ConfirmView);
